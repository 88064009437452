<template>
  <v-container>
    <v-row>
        <certificaciones-card
          v-for="certification in certifications"
          :key="certification.id"
          :value="certification"
          class="darken-4"
          height="100px"
          width="100%"
          contain
        />
    </v-row>
  </v-container>
</template>

<script>
  // Utilities
  import {
    mapState,
  } from 'vuex'

  export default {
    name: 'Certificaciones',
    components: {
      CertificacionesCard: () => import('@/components/base/CertificacionesCard'),
    },
    computed: {
      ...mapState(['certifications']),
    },
  }
</script>

<style scoped>
  .certificaciones {
    background-color: #C1DDDE;
  }
</style>
